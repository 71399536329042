import * as React from "react"
import * as styles from "./photos.module.scss"

const BaseImage = ({ imgSrc }) => {
    return (
        <div className={styles.baseImgContainer}>
            <img src={imgSrc} className={styles.baseImg} />
        </div>
    )
}

const DuoImage = ({ imgSrc, imgSrc2 }) => {
    return (
        <div className={styles.baseImgContainer}>
            <img src={imgSrc} className={styles.duoImg} />
            <img src={imgSrc2} className={styles.duoImg} />
        </div>
    )
}

export { BaseImage, DuoImage }
