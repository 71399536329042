import * as React from "react"
import Layout from "../components/layout"
import { BaseImage, DuoImage } from "../components/photos"
import * as styles from "./index.module.scss"
import pic1 from "../images/jc/1.png"
import d1 from "../images/jc/d1.png"
import d2 from "../images/jc/d2.png"
import pic3 from "../images/jc/3.png"
import d3 from "../images/jc/d3.png"
import d4 from "../images/jc/d4.png"
import pic5 from "../images/jc/5.png"
import pic6 from "../images/jc/6.png"
import pic7 from "../images/jc/7.png"
import pic8 from "../images/jc/8.png"
import pic9 from "../images/jc/9.png"

class JasonCasey extends React.Component {
    render() {
        return (
            <Layout title="Portraits - Jason/Casey">
                <div className={styles.photoBlog}>
                    <p>
                        <a href="/">
                            Home
                        </a>
                        <h3>Jason and Casey</h3>
                        <div>Point Reyes - October 2022</div>
                    </p>
                    <BaseImage imgSrc={pic1} />
                    <DuoImage imgSrc={d1} imgSrc2={d2} />
                    <BaseImage imgSrc={pic3} />
                    <DuoImage imgSrc={d3} imgSrc2={d4} />
                    <BaseImage imgSrc={pic5} />
                    <BaseImage imgSrc={pic6} />
                    <BaseImage imgSrc={pic7} />
                    <BaseImage imgSrc={pic8} />
                    <BaseImage imgSrc={pic9} />
                </div>
            </Layout>
        )
    }
}

export default JasonCasey
